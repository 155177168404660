import { getGuideProgress } from '@api/guide';
import { createQuery } from '@lib/cache-query-client';

enum GuideQueryKey {
    GetGuideProgress = 'getGuideProgress',
}

const getGuideProgressQuery = createQuery(GuideQueryKey.GetGuideProgress, () => [
    [GuideQueryKey.GetGuideProgress],
    async () => {
        const { data } = await getGuideProgress();

        return data;
    },
    { refetchOnWindowFocus: false },
]);

export { GuideQueryKey, getGuideProgressQuery };
