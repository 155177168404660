import styled, { css } from 'styled-components';

import { Link } from '@lib/i18n';

const createHigherLevelConnection = ({ connectionAreaWidth }) => css`
    width: ${connectionAreaWidth}px;
    background: none;
    border-radius: 0px 15px;
    border-left: 2px solid #b4b4b4;
    border-bottom: 2px solid #b4b4b4;
    left: ${-connectionAreaWidth / 2 - 1}px;
`;

const createLowerLevelConnection = ({ connectionAreaWidth }) => css`
    width: ${connectionAreaWidth}px;
    background: none;
    border-radius: 15px 0px;
    border-left: 2px solid #b4b4b4;
    border-top: 2px solid #b4b4b4;
    left: ${-connectionAreaWidth / 2 - 1}px;
`;

export const Dot = styled.div<{
    connectionAreaWidth: number;
}>`
    position: absolute;
    width: 14px;
    height: 14px;

    left: ${(props) => props.connectionAreaWidth / 2}px;
    top: 50%;
    border-radius: 50%;
    background: #b4b4b4;
    transform: translateY(-50%) translateX(-50%);
    transition: 0.2s ease;

    z-index: 11;
    display: flex;
`;

const HierarchyListItem = styled(Link)<{
    $isLatestItemInList: boolean;
    $isFirstItemInList: boolean;
    $previousItemLevel: number;
    $nextItemLevel: number;
    $itemLevel: number;
    $connectionAreaWidth: number;
    $isActive?: boolean;
}>`
    position: relative;
    display: block;

    padding: var(--indent-m);
    padding-left: ${(props) => props.$connectionAreaWidth + 12}px;
    border-radius: 10px;

    margin-left: ${(props) => props.$itemLevel * props.$connectionAreaWidth}px;
    line-height: 1.4em;
    word-wrap: break-word;
    color: inherit;
    text-decoration: none;

    transition: background 0.2s ease;

    ${(props) =>
        props.$isActive &&
        `
        background: #eaeaea;

        & > ${Dot} {
            transform: translateY(-50%) translateX(-50%) scale(1.2);
            box-shadow: 0 0 0 4px #eaeaea;
        }
    `}

    &:hover {
        background: #eaeaea;

        & > ${Dot} {
            transform: translateY(-50%) translateX(-50%) scale(1.2);
            box-shadow: 0 0 0 4px #eaeaea;
        }
    }

    &::before {
        content: '';

        position: absolute;
        width: 2px;
        height: 50%;
        left: ${(props) => props.$connectionAreaWidth / 2 - 1}px;
        top: 0px;
        background: #b4b4b4;
        z-index: 2;

        ${(props) =>
            props.$previousItemLevel < props.$itemLevel &&
            createHigherLevelConnection({
                connectionAreaWidth: props.$connectionAreaWidth,
            })}

        ${(props) =>
            props.$isFirstItemInList &&
            css`
                display: none;
            `}
    }

    &::after {
        content: '';

        position: absolute;
        width: 2px;
        height: 50%;
        left: ${(props) => props.$connectionAreaWidth / 2 - 1}px;
        bottom: 0px;
        background: #b4b4b4;
        z-index: 2;

        ${(props) =>
            props.$nextItemLevel < props.$itemLevel &&
            createLowerLevelConnection({
                connectionAreaWidth: props.$connectionAreaWidth,
            })}

        ${(props) =>
            props.$isLatestItemInList &&
            css`
                display: none;
            `}
    }
`;

export default HierarchyListItem;
