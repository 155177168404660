import { IGuideProgress, IModule } from '@interfaces/guide';
import { IQuestion } from '@interfaces/questions';

import httpClient, { ApiMethod } from '@lib/http';

export enum GuideGptQuickAction {
    Terminology = 'terminology',
    ArticleSummary = 'article_summary',
    AskMeAnything = 'ask_me_anything',
    ArticleParaphrase = 'article_paraphrase',
}

export const getGuideQuestions: ApiMethod<
    { questions: IQuestion[] },
    { questionsIds: number[] }
> = (params, options) => {
    return httpClient.get(
        `/api/v1/questions/guide/${params.questionsIds.join(',')}`,
        options,
    );
};

export const getGuideModules: ApiMethod<IModule[]> = (_, options) => {
    return httpClient.get('/api/v1/guide/modules', options);
};

export const getLandingStats: ApiMethod<
    { amountUsers: number; amountSubmissions: number },
    undefined
> = (_, options) => {
    return httpClient.get('/api/v1/user/landing-stats', options);
};

export const getGuideModule: ApiMethod<IModule, { moduleId: string }> = (
    params,
    options,
) => {
    return httpClient.get(`/api/v1/guide/modules/${params.moduleId}`, options);
};

export const getGuideModuleByChapter: ApiMethod<IModule, { chapterId: string }> = (
    params,
    options,
) => {
    return httpClient.get(`/api/v1/guide/module-by-chapter/${params.chapterId}`, options);
};

export const getNextChapter: ApiMethod<
    { chapter: { id: string; title: string } },
    { chapterId: string }
> = (params, options) => {
    return httpClient.get(`/api/v1/guide/next-chapter/${params.chapterId}`, options);
};

export const getChapterProgress: ApiMethod<
    { progress: number },
    { chapterId: string; moduleId: string }
> = (params, options) => {
    return httpClient.get(
        `/api/v1/guide/progress/${params.moduleId}/${params.chapterId}`,
        options,
    );
};

export const getGuideProgress: ApiMethod<IGuideProgress[], undefined> = (_, options) => {
    return httpClient.get('/api/v1/guide/progress', options);
};

export const markChapterAsComplete: ApiMethod<
    IGuideProgress[],
    { moduleId: string; chapterId: string }
> = (params, options) => {
    return httpClient.post('/api/v1/guide/complete', params, options);
};

export const createGptExplanationOperation: ApiMethod<
    { id: string },
    {
        chapterId: string;
        prompt?: string;
        quickAction?: GuideGptQuickAction;
    }
> = (params, options) => {
    return httpClient.post('/api/v1/guide/gpt-explanation', params, options);
};
