import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';

import { loadProfile } from '@store/actions/user/profileActions';
import { parseUrl } from '@lib/utils';

/*
 * Хук для загрузки общих для всех страниц данных на стороне клиента.
 */
const useLoadCommonData = () => {
    const dispatch = useDispatch();

    const { asPath } = useRouter();
    const { pathname } = parseUrl(asPath);

    useEffect(() => {
        dispatch(loadProfile());
    }, [dispatch, pathname]);
};

export { useLoadCommonData };
