import { FC } from 'react';

import { faTelegram, faVk } from '@fortawesome/free-brands-svg-icons';
import { faAt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useTranslation } from '@lib/i18n';

import LanguageSelector from './LanguageSelector';

import {
    StyledFooter,
    StyledFooterBlock,
    StyledFooterBlockContent,
    StyledFooterBlockSocialContent,
    StyledFooterBlockTitle,
    StyledFooterContent,
    StyledFooterContentDivider,
    StyledFooterContentInfo,
    StyledFooterContentSocial,
    StyledFooterLanguageWrapper,
} from './StyledFooter';

const Footer: FC = () => {
    const { t } = useTranslation('footer');

    return (
        <StyledFooter>
            <StyledFooterContent>
                <StyledFooterContentInfo>
                    <StyledFooterBlock>
                        <StyledFooterBlockTitle>
                            {t('for_customers')}
                        </StyledFooterBlockTitle>
                        <StyledFooterBlockContent>
                            <a href="/terms-of-use" target="__blank">
                                {t('TERMS_OF_USE')}
                            </a>
                        </StyledFooterBlockContent>
                    </StyledFooterBlock>

                    <StyledFooterBlock>
                        <StyledFooterBlockTitle>
                            {t('for_companies')}
                        </StyledFooterBlockTitle>
                        <StyledFooterBlockContent>
                            <a
                                aria-label={t('adv')}
                                href="/static/docs/adv.pdf"
                                target="_blank"
                            >
                                {t('adv')}
                            </a>

                            <a
                                aria-label={t('payment_as_a_company')}
                                href="/static/docs/legal_entity_payment.pdf"
                                target="_blank"
                            >
                                {t('payment_as_a_company')}
                            </a>
                        </StyledFooterBlockContent>
                    </StyledFooterBlock>

                    <StyledFooterBlock>
                        <StyledFooterBlockTitle>{t('navigation')}</StyledFooterBlockTitle>
                        <StyledFooterBlockContent>
                            <a aria-label={t('course')} href="/guide">
                                {t('course')}
                            </a>

                            <a aria-label={t('trainer')} href="/trainer">
                                {t('trainer')}
                            </a>

                            <a aria-label={t('sandbox')} href="/sandbox">
                                {t('sandbox')}
                            </a>

                            <a aria-label={t('handbook')} href="/handbook">
                                {t('handbook')}
                            </a>

                            <a
                                aria-label={t('interview_questions')}
                                href="/interview-questions"
                            >
                                {t('interview_questions')}
                            </a>
                        </StyledFooterBlockContent>
                    </StyledFooterBlock>
                </StyledFooterContentInfo>

                <StyledFooterContentDivider />

                <StyledFooterContentSocial>
                    <StyledFooterBlock>
                        <StyledFooterBlockTitle>{t('contact_us')}</StyledFooterBlockTitle>
                        <StyledFooterBlockSocialContent>
                            <a
                                aria-label={t('contact_us')}
                                href="mailto:feedback@sql-academy.org"
                            >
                                <div className="social-platform-item telegram">
                                    <FontAwesomeIcon icon={faAt} />
                                </div>
                            </a>

                            <a
                                aria-label={t('to_telegram')}
                                href="https://t.me/sqlacademyofficial"
                                target="_blank"
                            >
                                <div className="social-platform-item telegram">
                                    <FontAwesomeIcon icon={faTelegram} />
                                </div>
                            </a>

                            <a
                                aria-label={t('to_vk')}
                                href="https://vk.com/sqlacademy"
                                target="_blank"
                            >
                                <div className="social-platform-item vk">
                                    <FontAwesomeIcon icon={faVk} />
                                </div>
                            </a>
                        </StyledFooterBlockSocialContent>
                    </StyledFooterBlock>

                    <StyledFooterLanguageWrapper>
                        <LanguageSelector />
                    </StyledFooterLanguageWrapper>
                </StyledFooterContentSocial>
            </StyledFooterContent>
        </StyledFooter>
    );
};

export default Footer;
