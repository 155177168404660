import { IGuideProgress, IModuleChapter } from '@interfaces/guide';

export const getFlatChapters = <
    T extends Pick<IModuleChapter, 'children'>,
    R extends T & { itemLevel: number; children?: R[] },
>(
    items: T[],
    itemLevel = 0,
): R[] =>
    items.reduce<R[]>((list, item) => {
        const leveledItem = item as unknown as R;
        leveledItem.itemLevel = itemLevel;

        list.push(leveledItem);
        list.push(...getFlatChapters(leveledItem.children || [], itemLevel + 1));

        return list;
    }, []);

export const getProgressItem = (params: {
    moduleId: string;
    chapterId: string;
    progress: IGuideProgress[];
}) => {
    const { moduleId, chapterId, progress } = params;

    return progress.find(
        (item) => item.moduleId === moduleId && item.chapterId === chapterId,
    );
};
