import { FC, Fragment } from 'react';

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

import { css } from 'styled-components';

import Hierarchy from '@components/Common/Hierarchy';

import { IModule } from '@interfaces/guide';
import { getFlatChapters, getProgressItem } from '@lib/guide';
import { useTranslation, withTranslation } from '@lib/i18n';
import { isAuthorizedSelector } from '@store/selectors/user';

import { getGuideProgressQuery } from '../queries';

import {
    CheckIcon,
    GuideContentWrapper,
    ModuleDescription,
    ModuleInfo,
    ModuleNumber,
    ModuleStructure,
    ModuleTitle,
} from './StyledComponents';

type ContentProps = {
    modules: IModule[];
};

const { useGetGuideProgress } = getGuideProgressQuery;

const GuideContent: FC<ContentProps> = (props) => {
    const { modules } = props;

    const { t } = useTranslation('guide');

    const isAuthorized = useSelector(isAuthorizedSelector);

    const { data: progress } = useGetGuideProgress(undefined, { enabled: isAuthorized });

    return (
        <GuideContentWrapper>
            {modules.map((module, index) => {
                const chapters = getFlatChapters(module.chapters).map((chapter) => {
                    const moduleId = module.id;
                    const chapterId = chapter.id;

                    const progressItem = progress
                        ? getProgressItem({ moduleId, chapterId, progress })
                        : undefined;

                    return {
                        id: chapter.id,
                        href: `/guide/${chapter.id}`,
                        title: chapter.title,
                        itemLevel: chapter.itemLevel,
                        dotContent: progressItem?.isCompleted && (
                            <CheckIcon icon={faCheckCircle} />
                        ),
                        dotCss: css`
                            box-shadow: 0 0 0 4px var(--white);
                        `,
                    };
                });

                return (
                    <Fragment key={index}>
                        <ModuleInfo>
                            <ModuleNumber>
                                {t('MODULE')} {module.order}
                            </ModuleNumber>

                            <ModuleTitle>{module.title}</ModuleTitle>

                            <ModuleDescription>{module.description}</ModuleDescription>
                        </ModuleInfo>

                        <ModuleStructure>
                            <Hierarchy items={chapters} />
                        </ModuleStructure>
                    </Fragment>
                );
            })}
        </GuideContentWrapper>
    );
};

export default withTranslation('guide')(GuideContent);
