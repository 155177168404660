import styled from 'styled-components';

export const StyledFooter = styled.div`
    position: relative;
    width: 100%;
    box-shadow: rgba(33, 33, 33, 0.1) 0px 8px 14px;
    background: var(--white);
`;

export const StyledFooterContentInfo = styled.div`
    display: flex;
    gap: 50px;
`;

export const StyledFooterContentSocial = styled.div`
    display: flex;
    gap: 50px;
    justify-content: space-between;
    align-items: center;
`;

export const StyledFooterLanguageWrapper = styled.div``;

export const StyledFooterContent = styled.div`
    max-width: var(--max-width-page-content);
    margin: 0 auto;
    padding: var(--indent-xxxl) var(--indent-xxl);

    @media screen and (max-width: 1000px) {
        padding: var(--indent-xxxl) var(--indent-xl);
    }

    @media screen and (max-width: 580px) {
        padding: var(--indent-xl);

        ${StyledFooterContentInfo}, ${StyledFooterContentSocial} {
            gap: 30px;
            flex-direction: column;
            align-items: unset;
        }

        ${StyledFooterLanguageWrapper} {
            & > div {
                width: 100%;
            }
        }
    }
`;

export const StyledFooterContentDivider = styled.hr`
    border: 2px solid var(--background-page);
    border-radius: 10px;
    margin: var(--indent-xxl) 0px;
`;

export const StyledFooterBlock = styled.div`
    a {
        color: var(--dark);
        text-decoration: none;
    }
`;

export const StyledFooterBlockTitle = styled.div`
    font-family: var(--font-900);
    font-size: 1em;
`;

export const StyledFooterBlockContent = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: var(--indent-l);
    gap: var(--indent-m);
`;

export const StyledFooterBlockSocialContent = styled(StyledFooterBlockContent)`
    display: flex;
    flex-direction: row;

    a {
        display: flex;
        align-items: center;
    }

    .social-platform-item {
        color: #333;

        display: flex;
    }

    .vk {
        font-size: 30px;
    }

    .telegram {
        font-size: 28px;
    }
`;
