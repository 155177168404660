import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const ModuleInfo = styled.div`
    max-width: 400px;
`;

export const ModuleNumber = styled.div`
    font-size: 0.95rem;
    font-family: 'font300', 'Arial';
    color: #a5a5a5;
`;

export const ModuleTitle = styled.div`
    font-size: 1.2rem;
    font-family: 'font700', 'Arial';
`;

export const ModuleDescription = styled.div`
    margin-top: 15px;
    font-size: 0.95rem;
`;

export const ModuleStructure = styled.div`
    width: 100%;
    overflow: hidden;

    @media screen and (max-width: 1000px) {
        margin-left: 0px;
    }
`;

export const GuideContentWrapper = styled.div`
    position: relative;
    font-size: 1.03em;
    line-height: 1.5em;
    max-width: 920px;
    margin: -115px auto 100px;
    padding: 30px 50px 30px 50px;
    box-sizing: border-box;

    border-radius: var(--border-radius-xl);
    box-shadow: var(--shadow-xl);
    background: var(--white);

    display: grid;
    grid-template-columns: 1fr 1fr;

    grid-gap: 40px;

    @media screen and (max-width: 850px) {
        padding: 15px 25px 35px 25px !important;
        border-radius: 0;
    }

    @media screen and (max-width: 680px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }
`;

export const CheckIcon = styled(FontAwesomeIcon)`
    width: 100%;
    height: 100%;
    color: green;
    background-color: var(--white);
    border-radius: 50%;
`;
