import {
    forwardRef,
    memo,
    ReactElement,
    ReactNode,
    useImperativeHandle,
    useRef,
} from 'react';

import { CSSProp } from 'styled-components';

import HierarchyListItem, { Dot } from './HierarchyListItem';

type HierarchyProps = {
    items: {
        id: string;
        title: string;
        href: string;
        itemLevel: number;
        dotContent?: ReactNode;
        dotCss?: CSSProp;
    }[];
    activeItemId?: string;
    connectionAreaWidth?: number;
    onItemPress?: () => void;
    renderItem?: (params: {
        previousItemLevel: number;
        nextItemLevel: number;
        isLatestItemInList: boolean;
        isFirstItemInList: boolean;
        itemLevel: number;
        children: ReactNode;
        connectionAreaWidth: number;
    }) => ReactElement;
};

type HierarchyRef = {
    getActiveItemElement: () => HTMLAnchorElement;
};

const Hierarchy = forwardRef<HierarchyRef, HierarchyProps>((props, ref) => {
    const { items, connectionAreaWidth = 40, activeItemId, onItemPress } = props;

    const refs = useRef<HTMLAnchorElement[]>([]);

    useImperativeHandle(
        ref,
        () => ({
            getActiveItemElement: () => {
                return refs.current[items.findIndex((item) => item.id === activeItemId)];
            },
        }),
        [activeItemId, items],
    );

    return (
        <>
            {items.map((item, index, list) => {
                const isLatestItemInList = index === list.length - 1;
                const isFirstItemInList = index === 0;
                const itemLevel = item.itemLevel;

                const previousItemLevel =
                    list[index - 1] !== undefined
                        ? list[index - 1].itemLevel
                        : item.itemLevel;

                const nextItemLevel =
                    list[index + 1] !== undefined
                        ? list[index + 1].itemLevel
                        : item.itemLevel;

                return (
                    <HierarchyListItem
                        ref={(ref) => {
                            ref && (refs.current[index] = ref);
                        }}
                        key={index}
                        $previousItemLevel={previousItemLevel}
                        $nextItemLevel={nextItemLevel}
                        $isLatestItemInList={isLatestItemInList}
                        $isFirstItemInList={isFirstItemInList}
                        $itemLevel={itemLevel}
                        $connectionAreaWidth={connectionAreaWidth}
                        $isActive={activeItemId === item.id}
                        href={item.href}
                        onClick={onItemPress}
                    >
                        <Dot css={item.dotCss} connectionAreaWidth={connectionAreaWidth}>
                            {item.dotContent}
                        </Dot>

                        {item.title}
                    </HierarchyListItem>
                );
            })}
        </>
    );
});

Hierarchy.displayName = 'Hierarchy';

export type { HierarchyRef };

export default memo(Hierarchy);
