import Dropdown, {
    StyledDropdownSelector,
    StyledDropdownItems,
    StyledDropdownItem,
    Arrow,
} from '@components/Common/Dropdown';

import { useRouter, useTranslation } from '@lib/i18n';

import './index.scss';

const options = [
    { value: 'en', label: 'English' },
    { value: 'ru', label: 'Русский' },
];

const LanguageSelector = () => {
    const { lang } = useTranslation();

    const router = useRouter();

    const onChangeLanguage = async (language) => {
        const languageValue = language.value;

        router.push(router.asPath, router.asPath, { locale: languageValue });
    };

    return lang ? (
        <Dropdown
            trigger={['click', 'hover']}
            animation="slide-up"
            mouseEnterDelay={0.1}
            mouseLeaveDelay={0.2}
            css={{ marginLeft: 'auto' }}
            overlay={
                <StyledDropdownItems>
                    {options.map((option) => (
                        <StyledDropdownItem
                            key={option.value}
                            onClick={() => onChangeLanguage(option)}
                            className="dropdown-item"
                        >
                            {option.label}
                        </StyledDropdownItem>
                    ))}
                </StyledDropdownItems>
            }
            className="language-selector"
        >
            <StyledDropdownSelector
                css={`
                    width: 110px;
                `}
            >
                {options.find((option) => option.value === lang).label}

                <Arrow size={14} />
            </StyledDropdownSelector>
        </Dropdown>
    ) : null;
};

export default LanguageSelector;
